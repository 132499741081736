import React, { useState, useEffect, Fragment } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { connect } from "react-redux";
import { addAbstruct } from "../../actions/research";

const RegisterationModal = ({ lang, addAbstruct, theHubSource, id }) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  const [Research, setResearch] = useState({
    researchName: "",
    abstruct: "",
    theHub: "",
    author: "",
    email: "",
    phone: "",
    country: "",
    Organization: "",
    Qualification: "",
    Degree: "",
    author1: "",
    email1: "",
    author2: "",
    email2: "",
  });
  const [mss, setmss] = useState("");
  const [paper, setCV] = useState("");
  const [filename, setFilename] = useState(" CV / تحميل ملف السيرة الذاتية ");
  const onChange = (e) => {
    setResearch({ ...Research, [e.target.name]: e.target.value });
  };

  const onChangefile = (e) => {
    if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.target.files[0].type === "application/msword"
    ) {
      setCV(e.target.files[0]);
      setFilename(e.target.files[0].filename);
    } else {
      M.toast(
        {
          html: "الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة pdf او doc",
        },
        { outDuration: 500 }
      );
      setmss("الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة pdf او doc");
    }
  };

  const onChangeSelect = (selected) => {
    setResearch({ ...Research, theHub: selected.value });
  };

  let options = [];
  theHubSource.map((hub) =>
    options.push({
      value: hub,
      label: hub,
    })
  );

  const {
    researchName,
    abstruct,
    theHub,
    author,
    email,
    phone,
    country,
    Organization,
    Qualification,
    Degree,
    author1,
    email1,
    author2,
    email2,
  } = Research;

  const onSubmit = () => {
    if (
      researchName === "" ||
      abstruct === "" ||
      // theHub === "" ||
      author === "" ||
      email === "" ||
      phone === "" ||
      country === "" ||
      Organization === "" ||
      Qualification === "" ||
      Degree === "" ||
      filename === " CV / تحميل ملف السيرة الذاتية "
    ) {
      M.toast(
        { html: "كل الحقول مطلوبة عدا الباحث التاني و الثالث" },
        { outDuration: 500 }
      );
    } else {
      addAbstruct(
        paper,
        researchName,
        abstruct,
        theHub,
        author,
        email,
        phone,
        country,
        Organization,
        Qualification,
        Degree,
        author1,
        email1,
        author2,
        email2,
        id
      );
      M.toast(
        {
          html: `تم ارسال بيانات التسجيل علي الايميل ${email} في حالة عدم الرد في مدة اقصاها 10 ايام يمكنك الاتصال بالمنسقين`,
        },
        { outDuration: 500 }
      );
      setResearch({
        researchName: "",
        abstruct: "",
        theHub: "",
        author: "",
        email: "",
        phone: "",
        country: "",
        Organization: "",
        Qualification: "",
        Degree: "",
        author1: "",
        email1: "",
        author2: "",
        email2: "",
      });
    }
  };

  return (
    <div id="register" className="modal">
      <div className="modal-content">
        {lang === "ar" ? (
          <Fragment>
            <h4 className="center">ارسال الملخص</h4>
            <br />
            <ol className="red-text">
              <li>كل الحقول مطلوبة عدا الباحث الثاني و الثالث </li>
              <li>
                يجب ادخال الملخص باللغتين العربية و الانجليزية في حقل الملخص
              </li>
              <li>يرجى تحميل السيرة الذاتية في احدي الصيغتين (doc او pdf) </li>
              <li>
                في حالة عدم استقبالك لاي رسالة على بريدك الالكتروني الرجاء
                التواصل مع اللجنة على البريد الالكتروني : ficv2020@zu.edu.ly
              </li>
            </ol>
          </Fragment>
        ) : (
          <Fragment>
            <h4 className="center">Submit the abstract</h4>
            <br />
            <ol className="red-text left-align" style={{ direction: "rtl" }}>
              <li>
                All the fields are required except the fields released to the
                second and third author{" "}
              </li>
              <li>
                {" "}
                The abstract must be submitted both in Arabic and English using
                the abstract field.{" "}
              </li>
              <li>
                {" "}
                Please upload your CV in one of these format (doc or pdf){" "}
              </li>
              <li>
                If you do not receive a replay to your e-mail, please do not
                hesitate to contact us with via the E-mail: ficv2020@zu.edu.ly
              </li>
            </ol>
          </Fragment>
        )}
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="researchName"
              value={researchName}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Title of Paper / عنوان الورقة العلمية
            </label>
          </div>
        </div>
        <Select
          options={options}
          name="department"
          placeholder={<div>Choose the Topic / اختر المحور </div>}
          onChange={onChangeSelect}
          required
        />
        <br />
        <label htmlFor="firstName" className="active">
          Abstaruct / ملخص الورقة
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={abstruct}
          onChange={(event, editor) => {
            const data = editor.getData();
            setResearch({ ...Research, abstruct: data });
          }}
        />
        <div className="row ">
          <div className="input-field">
            <input
              type="text"
              name="author"
              value={author}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Name of Author 1 / اسم المشارك الذى سيعرض الورقة
            </label>
          </div>
        </div>
        <div className="row ">
          <div className="input-field">
            <input
              type="text"
              name="email"
              value={email}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Email of Author 1 / البريد الالكترونى
            </label>
          </div>
        </div>
        <div className="row ">
          <div className="input-field">
            <input
              type="number"
              name="phone"
              value={phone}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Phone of Author 1 / رقم الهاتف
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="country"
              value={country}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Country / الدولة *
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="Organization"
              value={Organization}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Organization of Authors / الجهة التابع لها المشارك ( جامعة- كلية /
              مركز بحثى / الخ) *
            </label>
          </div>
        </div>{" "}
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="Qualification"
              value={Qualification}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Qualification / لمؤهل العلمى / التخصص
            </label>
          </div>
        </div>{" "}
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="Degree"
              value={Degree}
              onChange={onChange}
              required
            />
            <label htmlFor="firstName" className="active">
              Degree / الدرجة العلمية *
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="author1"
              value={author1}
              onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              Name of Author 2 / اسم الباحث التاني ان وجد
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="email1"
              value={email1}
              onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              Email of Author 2 / البريد الالكترونى للباحث التاني{" "}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="author2"
              value={author2}
              onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              Name of Auther 3 / اسم الباحث الثالث ان وجد{" "}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="email2"
              value={email2}
              onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              Email of Author 3 البريد الالكترونى للباحث الثالث{" "}
            </label>
          </div>
        </div>
        <div className="file-field input-field">
          <div className="btn">
            <span>File</span>
            <input type="file" onChange={onChangefile} />
          </div>
          <div className="file-path-wrapper">
            <input
              className="file-path validate"
              type="text"
              // eslint-disable-next-line
              value={filename}
              required
            />
          </div>
        </div>
        <label htmlFor="firstName" className="active">
          يمكن تحميل ملف في (pdf , doc) هدا الحقل
        </label>
        <span className="red-text">{mss}</span>
      </div>
      <div className="modal-footer">
        <a
          href="#!"
          onClick={onSubmit}
          className="modal-close waves-effect blue waves-light btn"
        >
          ارسال
        </a>
      </div>
    </div>
  );
};

export default connect(null, { addAbstruct })(RegisterationModal);
