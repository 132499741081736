import axios from "axios";
import {
  GET_RESEARCHS,
  GET_CONFERANCE,
  GET_RESEARCH,
  RESEARCH_ERROR,
  ADD_RESEARCH,
  UPDATE_RESEARCH,
  CHANGE_LANG,
} from "./types";

// GET CONFERANCES
export const getConferance = (cid) => async (dispatch) => {
  try {
    const res = await axios.get(
      `http://db.conference.zu.edu.ly/api/conferance/${cid}`
    );

    dispatch({
      type: GET_CONFERANCE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// Get Researchs
export const getResearchs = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `http://db.conference.zu.edu.ly/api/researchs/${id}`
    );

    dispatch({
      type: GET_RESEARCHS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// // Get one Research
// export const getResearch = (id) => async (dispatch) => {
//   try {
//     const res = await axios.get(`https://conference.zu.edu.ly/api/ficv/${id}`);
//     dispatch({
//       type: GET_RESEARCH,
//       payload: res.data,
//     });
//   } catch (err) {
//     dispatch({
//       type: RESEARCH_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };
// export const ChangeLang = (lang) => async (dispatch) => {
//   dispatch({
//     type: CHANGE_LANG,
//     payload: lang,
//   });
// };

// Add Category
export const addAbstruct = (
  paper,
  researchName,
  abstruct,
  theHub,
  author,
  email,
  phone,
  country,
  Organization,
  Qualification,
  Degree,
  author1,
  email1,
  author2,
  email2,
  id
) => async (dispatch) => {
  const formData = new FormData();
  formData.append("Paper", paper);
  formData.append("researchName", researchName);
  formData.append("abstruct", abstruct);
  formData.append("theHub", theHub);
  formData.append("author", author);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("country", country);
  formData.append("Organization", Organization);
  formData.append("Qualification", Qualification);
  formData.append("Degree", Degree);
  formData.append("author1", author1);
  formData.append("email1", email1);
  formData.append("author2", author2);
  formData.append("email2", email2);

  try {
    const res = await axios.post(
      `http://db.conference.zu.edu.ly/api/researchs/${id}`,
      formData
    );
    dispatch({
      type: ADD_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const setResearch = (id) => (dispatch) => {
  dispatch({
    type: GET_RESEARCH,
    payload: id,
  });
};
