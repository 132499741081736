import React, { Fragment, useRef, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";

import { connect } from "react-redux";
import { setResearch } from "../../actions/research";

const ContactFilter = ({
  setResearch,
  lang,
  researchs: { researchs, research },
}) => {
  const [message, setmessage] = useState("");

  const text = useRef("");
  const onChange = async (e) => {
    if (text.current.value.length === 24) {
      let p = await researchs.find(
        (research) => research._id === text.current.value
      );
      setResearch(text.current.value);

      if (!p) {
        M.toast({
          html:
            "لا يوجد ورقة بهدا الرقم تآكد من رقم الورقة من بريدك الالكتروني",
          options: { outDuration: 10000 },
        });
        // eslint-disable-next-line
        {
          lang === "ar"
            ? setmessage(
                "لا يوجد ورقة بهدا الرقم تآكد من رقم الورقة من بريدك الالكتروني"
              )
            : setmessage("There Is No Any Paper With this ID");
        }
      } else if (p && (!p.status || p.status === "")) {
        M.toast({
          html: `تم استلام الورقة و يتم مراجعتها من قبل اللجنة العلمية : `,
          options: { outDuration: 10000 },
        });
        // eslint-disable-next-line
        {
          lang === "ar"
            ? setmessage(
                `آ.
          ${p.author} ,
          تم استلام الملخص و تتم مراجعته من قبل اللجنة العلمية
            `
              )
            : setmessage(`
        ${p.author} , 
        The abstract has been received and will be reviewed by the Scientific Committee
        `);
        }
      } else if (p && p.status === "accepted") {
        M.toast({
          html: "تم قبول الملخص ارجو الضغط علي الزر لتحميل الورقة   ",
          options: { outDuration: 10000 },
        });
      } else {
        M.toast({
          html: `آ.
          ${p.author} ,
          للآسف !
          لم يتم قبول ورقتك يمكنك الاطلاع علي بريدك للاطلاع علي مزيد من المعلومات`,
          options: { outDuration: 10000 },
        });
        // eslint-disable-next-line
        {
          lang === "ar"
            ? setmessage(
                `آ.
              ${p.author} ,
              للآسف !
              لم يتم قبول ورقتك يمكنك الاطلاع علي بريدك للاطلاع علي مزيد من المعلومات`
              )
            : setmessage(`
        ${p.author} , 
        Unfortunately, the abstract was not accepted to participate in the conference
        `);
        }
      }

      text.current.value = "";
    }
  };

  return (
    <Fragment>
      <div class="row">
        <div class="col s12">
          <h5>
            {lang === "ar"
              ? "للمراجعة عن حالة الملخص و تحميل الورقة في حالة القبول ادخل رمز الملخص "
              : "To Review The Status Of Paper"}
          </h5>
          <div class="input-field">
            <input
              class="white grey-text autocomplete"
              ref={text}
              type="text"
              placeholder={
                lang === "ar"
                  ? "ادخل رمز الورقة عدد رموز الورقة 24 رقم ..."
                  : "Please Enetr the ID of the Paper"
              }
              onChange={onChange}
              autofocus
              id="autocomplete-input"
            />
          </div>
          <h5>{message}</h5>
          {research && (
            <Fragment>
              {research.status === "accepted" && !research.paper ? (
                <Fragment>
                  <h5>
                    {lang === "ar"
                      ? `آ.
              ${research.author} ,
              تم قبول الملخص ارجو الضغط علي الزر لتحميل الورقة         
              `
                      : `
              ${research.author} , 
              The paper has been accepted. Please refer to the e-mail for more details.
              `}
                  </h5>
                  <a
                    href={`#paper`}
                    className="modal-trigger  hide-on-small-only btn-large red darken-2 waves-effect waves-light btn "
                  >
                    <i class="material-icons left">border_color</i>
                    {lang === "ar" ? "تحميل الورقة العلمية" : "Upload Research"}
                  </a>
                </Fragment>
              ) : (
                <Fragment>
                  {research.paper !== "" && research.status === "accepted" && (
                    <h5>
                      {lang === "ar"
                        ? `آ.
          ${research && research.author} ,
          تم استلام الورقة العلمية كاملة و يتم مراجعتها من اللجنة العلمية        
          `
                        : `
          ${research && research.author} , 
          The Research has been received and will be reviewed by the Scientific Committee
          `}
                    </h5>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  researchs: state.research,
});

export default connect(mapStateToProps, { setResearch })(ContactFilter);
