export const head1 = {
  ar: "المؤتمر الدولي الأول (الافتراضي)",
  en: "The first (virtual) international conference",
};

export const head2 = {
  ar:
    "بعنوان :  <br />التحوّل الرقمي في عصر المعرفة   ( الواقع ، التحديات ، الانعكاسات)",
  en:
    "Digital transformation in the age of knowledge <br /> (Reality - Challenges - Implications)",
};

export const head3 = {
  ar: "إجمالي الملخصات المستلمة ",
  en: "Total abstracts received",
};
export const head4 = {
  ar: "عدد الملخصات المقبولة ",
  en: "Total Abstracts Accepted",
};

export const head41 = {
  ar: "عدد الملخصات المرفوضة ",
  en: "Total Abstracts Rejected",
};

export const head5 = {
  ar: "موعد انعقاد المؤتمر بعد ",
  en: "",
};

export const head6 = {
  ar: "تمهيد",
  en: "Introduction",
};
export const Node = {
  ar: "ملاحظة :",
  en: "",
};
export const TNode = {
  ar: `السادة الباحثون نظرا لمطالبة العديد من الباحثين بتمديد فترة  قبول الملخصات و  ذلك لتزامنها مع  عطلة عيد الفطر المبارك  
  فلقد تقرر تمديد فترة قبول الملخصات الي  يوم 11-06-2020.والاعلان على الملخصات المقبولة 2020.06.12    . وبداية قبول الورقات البحثية كاملة..2020.06.13. واخر موعد قبول الورقات البحثية كاملة 2020.07.06 والاعلان عن الورقات المقبولة 2020.07.10 .وانعقاد المؤتمر 2020.07.12. `,
  en: "",
};

export const about1 = {
  ar: ` يعيش العالم الرقمي نموّاً في شتى المجالات الحياتية بشكل متزايد فقد أصبحت
    تكنولوجيا المعلومات تستخدم تدريجيا لتوصيل المعرفة والمهارات بطرق جديدة
    ومبتكرة ، يقترن هذا النمو بتغيرات مستقبلية في أسلوب العمل ونمطه ،
    اللذين يتأثران نفسيهما بالمناخ الحالي من انعدام اليقين الاقتصادي ،
    فضلا عن التحولات السياسية والأمنية والصحية ، فكان لزاما البحث والتفسير
    عن الأسباب والإشكاليات وكان هذا المؤتمر الدولي الافتراضي من اجل ايجاد
    حلولا لهذه الأشكاليات التي تعاني منها المنظمات والمجتمعات في العالم
    اجمع الذي اصبح متاحا بشكل فعال وسريع للاستفاذة من كل التجارب والخبرات
    المحلية والدولية في ظل تكنولوجيا المعلومات والمعرفة .`,
  en:
    "The digital world is increasingly penetrating into various areas of life. Information technology has gradually been used to communicate knowledge and skills in new and innovative ways. This penetration is coupled with future changes in work styles, which are affected by the current climate of economic uncertainty, as well as political, security and health transformations. It is imperative to research and explain causes and effects and this virtual international conference is to find solutions to some of the problems that afflict organisations and societies globally. Technology is advancing rapidly yet the education sector is not yet taking full advantage of the possibilities for effective and accessible ways of learning.  From sharing local and international experiences and expertise in the light of information technology and knowledge, this conference seeks to empower educators.",
};

export const about2 = {
  ar: `
    قواعد وشروط النشر بالمجلة ستنشر المداخلات والورقات البحثية المحكمة
    والمستوفية للشروط في عدد خاص بالمؤتمر وحسب الشروط التالية :`,
  en: "Journal publishing rules and conditions",
};
export const about3 = {
  ar: "اضغط هنا",
  en: "Click here",
};
export const about4 = {
  ar: "قواعد وشروط النشر",
  en: "Journal publishing rules and conditions",
};
export const about5 = {
  ar: `<li>أن يسهم البحث في تعميق المعرفة ضمن محاور المؤتمر</li>
    <li>
      أن يكون البحث مستوفيا لشروط البحث العلمي, بما في ذلك ملخص البحث
      وعرض المشكلة ومراجعة الأدبيات ذات العلاقة والمنهجية المتبعة و
      تحليل البيانات وعرض النتائج والتوصيات .
    </li>
    <li>تقبل البحوث باللغة العربية واللغة الانجليزية .</li>
    <li>إتباع القواعد اللغوية السليمة في كتابة البحث.</li>
    <li>
      ألا يكون البحث جزءاً من رسالة الماجستير أو الدكتوراه التي أعدها
      الباحث, وألا يكون قد سبق نشره , أو تم إرساله للنشر في مجلة اخرى
      وألا يكون قد تمت المشاركة به في مؤتمر أو ندوة أو ورشة عمل.
    </li>
    <li>
      أن يكون البحث مكتوبا على ورق حجم A4 بحيث لا يقل حجم البحث عن 15
      صفحة و لا يزيد على 25 صفحة.
    </li>
    <li>
      تخضع البحوث المقدمة إلى المجلة للفحص والتقييم العلمي حسب الأصول
      المتبعة ، وتتحدد صلاحيتها للنشر بناء على رأي المحكمين بها.
    </li>
    <li>
      
      يلتزم الباحث بإجراء تعديلات المحكمين بالمؤتمر على بحثه وفق
      التقارير المرسلة إليه، وموافاة المجلة بنسخة معدلة خلال مدة لا
      تزيد عن (15) يوماً .
    </li>
    <li>
      لا يجوز للباحث أن يطلب عدم نشر بحثه بعد إرساله للتحكيم إلا
      لأسباب تقتنع بها هيأة التحرير.
    </li>
    <li>
      
      يرفق بالبحث ملخص باللغتين العربية والانجليزية بما لا يزيد عن
      (200) كلمة، وتلي الملخص كلمات مفتاحية دالة لا تزيد عن (7 )
      للتعريف بنواحي البحث الرئيسة.
    </li>
    <li>
      يحق لهيأة التحرير - إذا رأت ضرورة لذلك - إجراء بعض التعديلات على
      البحث من دون المساس بمضمونه وبما يتوافق مع أسلوب المجلة في النشر
      .
    </li>
    <li>
      
      يكتب الباحث على ورقة منفصلة اسمه بالكامل ووظيفته الحالية ودرجته
      العلمية واسم المؤسسة التي يعمل بها وعنوانه مفصلاً وأرقام الهواتف
      والبريد الالكتروني، ويرفق مع البحث سيرة ذاتية مختصرة للتعريف
      بالباحث.
    </li>
    <li>
      يجب إثبات المصادر والمراجع في متن البحث بحيث يوضع اسم ولقب
      الباحث والسنة بين قوسين، وفي حالة الاقتباس المباشر يضاف رقم
      الصفحة.
    </li>
    <li>
      جميع المراجع المشار إليها في متن البحث يجب أن تدرج في قائمة
      المراجع في نهاية البحث قبل الملاحق – إن وجدت – وترتب ترتيباً
      هجائياً مبتدأه بالمراجع العربية أولاً ثم المراجع الأجنبية دون
      ترقيمها، وذلك وفقاً لما يلي
      <ul>
        <li>
          
          الكتب : اسم المؤلف أو المؤلفين ( السنة )، عنوان الكتاب ، اسم
          المحقق أو المترجم إن وجد ، (رقم الطبعة ؛ مكان النشر: اسم
          الناشر ) ، رقم الجزء . وفي حالة المراجع الأجنبية يكتب لقب
          المؤلف أو المؤلفين أولاً ثم بقية الاسم.
        </li>
        <li>
          
          البحث أو المقال المنشور في الدورية : اسم الكاتب أو الكتّاب (
          السنة) ، عنوان البحث أو المقال بين علامتي تنصيص ، اسم
          الدورية ، الجهة التي تصدر عنها الدورية، واسم البلد الصادرة
          عنها ، رقم المجلد ، رقم العدد . وفي حالة المراجع الأجنبية
          يكتب لقب الكاتب أو الكتّاب أولاً ثم بقية الاسم.
        </li>
        <li>
          الرسائل الجامعية : اسم مقدم الرسالة ( السنة) ،عنوان الرسالة
          أو الأطروحة، اسم الجامعة المانحة للرسالة.
        </li>
        <li>
          
          مصادر ومراجع الانترنت : اسم المؤلف أو المؤلفين (السنة)
          ،عنوان الكتاب أو المقال أو البحث ، اسم الموقع وعنوانه ،
          وتاريخ زيارة الموقع .
        </li>
      </ul>
    </li>
    <li>لا ينشر للباحث أكثر من بحث واحد في كل عدد.</li>
    <li>
      يعتبر البحث المنشور ملكاً للمجلة وبالتالي لا يجوز إعادة نشر أي
      بحث منشور أو تم قبوله للنشر بأي شكل من الأشكال أو بأي لغة في أي
      جهة أخرى، إلا بإذن رسمي من رئيس هيأة التحرير.
    </li>
    <li>
      تكون النواحي الفنية في أعداد البحث على النحو التالي :
      <ul>
        <li>
          
          تكون الكتابة للبحوث العربية بالخط (Simplified Arabic ) ،
          وللبحوث باللغة الانجليزية بالخط ( Times New Roman ) مع ترك
          مسافة مفردة بين الأسطر.
        </li>
        <li>
          أن يكون حجم الخط على النحو التالي:
          <ol>
            <li> 18 (داكن) للعناوين الرئيسة .</li>
            <li>16 (داكن) للعناوين الفرعية.</li>
            <li>
              14 للمتن باللغة العربية و 12 للمتن باللغة الانجليزية.
            </li>
            <li>
              12 مائل للملخص باللغة العربية و 10 للملخص باللغة
              الانجليزية.
            </li>
            <li>10 للشرح أو التوضيح أسفل الصفحة.</li>
          </ol>
        </li>
        <li>
          أن تكون الهوامش على النحو التالي :
          <ol>
            <li>أعلى وأسفل 2.5 سم .</li>
            <li>أيمن 3 سم .</li>
            <li>أيسر 2.5 سم.</li>
          </ol>
        </li>
      </ul>
    </li>
    <li>
      يحق للجنة العلمية رفض الورقة العلمية بدون ابداء اسباب الرفض.
    </li>
    <li>
      تسلم البحوث والدراسات وجميع المراسلات المتعلقة من خلال رابط التسجيل المخصص لاستقبال البحوث الموجود بالموقع الالكتروني .
    </li>`,
  en: `<p>Interventions and research papers that meet the requirements will be published in a special proceedings of the conference journal, according to the following conditions:</p>
  <ol>
  <li>That the research contributes to deepening knowledge within the conference axes.</li>
  <li>That the research meets the requirements of scientific research, including the summary of the research, presentation of the problem, review of the relevant literature and the methodology used, data analysis and presentation of findings and recommendations.</li>
  <li>Research is accepted in Arabic and English.</li>
  <li>Proper linguistic rules are followed in writing the research.</li>
  <li>The research should not be part of a Master's or Doctoral thesis, it should not have been previously published or sent for publication to another journal, and it has not been presented in another conference, seminar or workshop.</li>
  <li>The research paper is between 15 and 25 pages.</li>
  <li>The research should be written on A4 size paper.</li>
  <li>Research submitted to the journal is subject to scientific examination and evaluation according to the established rules, and its suitability for publication is determined by the Editorial Board.</li>
  <li>The researcher is obliged to accept the judgement of the editorial board on his/her research and, according to feedback provided, should present a modified copy within a period not exceeding 15 days.</li>
  <li>The researcher may not request that his/her research not be published after sending it for evaluation, except for reasons agreed by the editorial board.</li>
  <li>Attached to the research should be a summary in Arabic and English of no more than 200 words, followed by no more than 7 key words to define the main aspects of the research.</li>
  <li>The Editorial Board has the right - if it deems necessary - to make some adjustments to the research, without prejudice to its content, and in accordance with the journal's method of publication.</li>
  <li>The researcher should write on a separate paper his/her full name, current job, academic degree, the name of the institution he/she works for, a detailed address, phone number and e-mail, and attach to the research a brief CV.</li>
  <li>Research sources must be referenced in the body of the research, giving name of the researcher/s and the year in parentheses. In the case of a direct quotation, the page number should be added.</li>
  <li>All references referred to in the body of the research must be included in the list of references at the end of the research, before the annexes - if any - and arranged in alphabetical order, starting with the Arabic references first and then the foreign references, according to the following:<ol>
  <li>Books:The name of the author or authors (year), the title of the book, the name of the  translator, edition number, place of publication: name of the publisher. In the case of foreign references, the surname of the author or authors is written first and then their initials.
  
  </li>
  <li>Journal publication:The name of the author or writers (year), the title of the research or article in quotation marks, the name of the journal, the volume number, issue number and page numbers.</li>
  <li>Theses:The name of the author of the thesis (year), the title of the thesis or thesis, the name of the university providing the thesis.</li>
  <li>Internet resources and references:The name of the author or authors (year), the title of the book, article or research, the URL of the site, and the date the site was accessed.</li>
   </ol>
   </li>
  <li>No more than one research paper per researcher will be published in the proceedings.</li>
  <li>The published research is considered the copyright property of the journal and therefore it is not permissible to republish in any way or in any language in any other publication, except with the official permission of the Editor-in-Chief.</li>
  <li>The technical aspects for papers are as follows:
  <ol>
  <li> Times New Roman and Simplified Arabic- Single spaced.- Font size:18 bold for headlines-16 bold for subtitles.</li>
  <li>14 for the body in Arabic and 12 for the body in English.</li>
  <li>12 italic for abstract in Arabic and 10 for abstract in English.</li>
  <li>10 for explanation or clarification at the bottom of the page.</li>
  <li>The margins should be as follows:2.5 cm up and down, 3 cm right, 2.5 cm left.</li>
  </ol>
  </li>
  <li>All papers and correspondence to be submitted only through the designated address given in the conference announcements.</li>
  </ol>
  <p>Journal of the Faculty of Economics – University of Zawia, Zawiya-Libya</p>
  `,
};

export const about6 = {
  ar: ` مجلة كلية الاقتصاد – جامعة الزاوية ، الزاوية ليبيا .`,
  en: `Journal of the Faculty of Economics – University of Zawia, Zawiya, Libya`,
};
export const Gools1 = {
  ar: `أهداف المؤتمر`,
  en: `The objectives of the conference`,
};
export const Gools2 = {
  ar: `<li>التأكيد على أهمية التحول الرقمي في مختلف المجالات.</li>
  <li> تشجيع البحث العلمي في مجال التحول الرقمي .</li>
  <li>
    
    إبراز أهمية التحول الرقمي في تحقيق متطلبات التنمية المستدامة.
  </li>
  <li>
    
    تفعيل دور التعليم الإلكتروني للرفع من كفاءة ذوي الاحتياجات الخاصة،
    مع مراعاة الفروق الفردية بينهم.
  </li>
  <li>
    
    الرفع من مهارات الأداء لتحقيق متطلبات الجودة داخل المؤسسات التعليمية
    والمراكز البحثية.
  </li>
  <li>
    
    السعي نحو تطبيق تكنولوجيا المعلومات في مختلف المجالات الاقتصادية
    (الاقتصاد الرقمي).
  </li>
  <li>
    تعزيز علاقات التواصل والتبادل الثقافي والمعرفة، على المستويين المحلي
    والدولي.
  </li>`,
  en: `
	<li>Emphasise the importance of digital transformation in various fields.</li>
		<li>Encourage scientific research in the field of digital transformation.</li>
		<li>Highlight the importance of digital transformation in achieving the requirements of sustainable development.</li>
		<li>Activate the role of e-learning to raise the efficiency of people with special needs, taking into account individual differences between them.</li>
		<li>Increase performance skills to achieve quality requirements within educational institutions and research centres.</li>
		<li>Seek to apply information technology in various economic fields (digital economy).</li>
		<li>Promote communication, cultural exchange and knowledge at both local and international levels.</li>`,
};

export const m1 = {
  ar: "محاور المؤتمر",
  en: "Conference themes",
};

export const m2 = {
  ar: "المحور الأول: التدريب الإلكتروني.",
  en: "The first axis: e-training",
};
export const m3 = {
  ar: `<ol>
    <li> 
      تحديد الاحتياجات التدريبية من خلال أساليب التقنية الحديثة.
    </li>
    <li>
      التدريب الإلكتروني وانعكاساته على الرفع من كفاءة ذوي
      الاحتياجات الخاصة.
    </li>
    <li>
      دور التدريب الإلكتروني في تحسين جودة البرامج التدريبية.
    </li>
    <li>
      فاعلية التدريب الإلكتروني في تطوير الموارد البشرية داخل
      المؤسسات (الحكومية، الخاصة).
    </li>
  </ol>`,
  en: `<ol>
  <li>Defining training needs through modern technology methods.
</li>
<li>E-training and its implications for raising the efficiency of people with special needs.
</li>
  <li>The role of electronic delivery in improving the quality of training programmes.
</li>
  <li>The effectiveness of e-training in developing human resources within institutions (government, private).
</li>
</ol>`,
};
export const m4 = {
  ar: "المحور الثاني: التحول الرقمي والتنمية المستدامة.",
  en: "The second axis: digital transformation and sustainable development.",
};
export const m5 = {
  ar: `<ol>
    <li> دور التحول الرقمي في تحسين التنمية البشرية. </li>
    <li> دور التحول الرقمي في تحسين التنمية الاقتصادية. </li>
    <li> دور التحول الرقمي في تحسين التنمية السياحية. </li>
    <li> دور التحول الرقمي في تحسين التنمية البيئية.</li>
  </ol>`,
  en: `	<ol>
  <li>The role of digital transformation in improving human development.</li>
  <li>The role of digital transformation in improving economic development.</li>
  <li>The role of digital transformation in improving tourism development.</li>
  <li>The role of digital transformation in improving environmental development.</li>
  </ol>`,
};
export const m6 = {
  ar: `
    المحور الثالث: دور التعليم الإلكتروني داخل المؤسسات التعليمية
    والمراكز البحثية.`,
  en:
    "The third axis: the role of e-learning within educational institutions and research centres.",
};
export const m7 = {
  ar: `<ol>
    <li>
      
      استخدام الأساليب التقنية الحديثة في التعليم داخل المؤسسات
      التعليمية.
    </li>
    <li> تطبيق التقنيات الحديثة في البحوث العلمية.</li>
    <li>
      
      دور التعليم الإلكتروني في كفاءة التحصيل العلمي لذوي الاحتياجات
      الخاصة.
    </li>
    <li>
      
      دور التعليم الإلكتروني في تحقيق متطلبات الجودة داخل المؤسسات
      التعليمية والمراكز البحثية..
    </li>
  </ol>`,
  en: `<ol>
  <li>The use of modern technological methods in education within educational institutions.</li>
  <li>Applying modern technologies in scientific research.</li>
  <li>The role of e-learning in the efficiency of educational attainment for people with special needs.</li>
  <li>The role of e-learning in achieving quality requirements within educational institutions and research centres.</li>
  </ol>`,
};

export const m8 = {
  ar: ` المحور الرابع: نظام الإدارة الإلكترونية.`,
  en: `The fourth axis: the electronic management system.`,
};
export const m9 = {
  ar: ` <ol>
  <li>
    
    تحديات التحول نحو نظام الإدارة الإلكترونية والحوكمة
    الالكترونية .
  </li>
  <li>
    
    دور الإدارة الإلكترونية في تحقيق الميزة التنافسية داخل مختلف
    المؤسسات.
  </li>
  <li>
    
    فاعلية تطبيق نظام الإدارة الإلكترونية أثناء حدوث الأزمات.
  </li>
  <li>
    
    إمكانية إحلال الإدارة الإلكترونية محل الإدارة التقليدية في بعض
    المجالات.
  </li>
</ol>`,
  en: `<ol>
  <li>Challenges of the transition to electronic management and e-governance.</li>
  <li>The role of electronic management in achieving competitive advantage within different institutions.</li>
  <li>The effectiveness of applying an electronic management system during a crisis.</li>
  <li>The possibility of electronic management replacing traditional management in some areas.</li>
  </ol>`,
};
export const m10 = {
  ar: `المحور الخامس: الاقتصاد الرقمي في عصر المعرفة.`,
  en: `The fifth axis: the digital economy in the age of knowledge.`,
};
export const m11 = {
  ar: `<ol>
  <li> أهمية تطبيق الاقتصاد الرقمي في تحقيق النمو الاقتصادي.</li>
  <li>
    
    دور التحول الرقمي في تدفق المعلومات والأموال بين دول العالم.
  </li>
  <li>
    
    ملاءمة التشريعات القانونية لتوفير المناخ الملائم لتطبيق
    الاقتصاد الرقمي.
  </li>
  <li> دور الاقتصاد الرقمي في جذب الاستثمارات الأجنبية.</li>
</ol>`,
  en: `<ol>
  <li>The importance of applying the digital economy in achieving economic growth.</li>
  <li>The role of digital transformation in the flow of information and money between countries of the world.</li>
  <li>The suitability of legislation to provide the appropriate climate for the application of the digital economy.</li>
  <li>The role of the digital economy in attracting foreign investment.</li>
  </ol>`,
};
export const m12 = {
  ar: `المحور السادس: تجارب ناجحة في تطبيق التحول الرقمي.`,
  en: `The sixth axis: successful experiences in the application of digital transformation.`,
};
export const m13 = {
  ar: `<ol>
  
  <li> دور المنظمات العربية والاقليمية في تفعيل التحول الرقمي.</li>
  <li> مقارنات ومقاربات بين سياسيات واستراتيجيات الدول النامية والدول الصاعدة والمتقدمة في التحول الرقمي.</li>
</ol>`,
  en: `<ol>
  <li>The role of Arab and regional organizations in activating the digital transformation.</li>
  <li> Comparisons and approaches betweenآ the policies and strategies of developing and emerging countries in digital transformation.</li>
  </ol>`,
};

export const s = {
  ar: `رعاة المؤتمر ...`,
  en: `Sponsors`,
};

export const comission = {
  ar: `لجان الإشراف على المؤتمر ...`,
  en: `Supervisory committees`,
};

export const c1 = {
  ar: `المنسقون...`,
  en: `Coordinators`,
};

export const c2 = {
  ar: `
  
  <div className="col m6">
    <h5>منسقوا دولة ليبيا : </h5>
    <ol>
      <li>د. حسن موتات : جامعة الزاوية  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 0914222854 </li>
      <li>أ. ياسين ابوسريويل : جامعة الزاوية  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 0912201491</li>
      <li>أ. أحمد ابراهيم سليمان : جامعة الزاوية  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 0928555319 </li>
      <li>أ. اسماء حسن العقيلي : جامعة بنغازي  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 0916537995 </li>
    </ol>
    </div>
    <div className="col m6">
  <h5>منسقوا الدول الأخرى : </h5>
    <ol>
      <li> د. فوزية مندرة : منسق أوروبا وأمريكا  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 00447472880050</li>
      <li>
       د. مرصالي حورية : منسق الجزائر والمغرب العربي <i
        class="material-icons teal-text"
        style={{ marginLeft: "15px" }}
      >
      phone
      </i>
        00213798481647
      </li>
      <li> د. منير الكبير : منسق دولة ماليزيا  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 00601137011603</li>
    </ol>
    <h6>لمزيدا من المعلومات الرجاء مراسلتنا على البريد الالكتروني التالي: FICV2020@zu.edu.ly</h6>
  </div>`,
  en: `<div className="col m6">
  <h5>Libya : </h5>
  <ol>
    <li>Hassan Mutat - Unversity Of Zawia<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 0914222854 </li>
    <li>Yassin Abu-Sriwill - Unversity Of Zawia  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 0912201491</li>
    <li>Ahmed Suleiman - Unversity Of Zawia  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 0928555319 </li>
    <li>Asmaa Al-Aqili - University Of Benghazi <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 0916537995 </li>
  </ol>
  </div>
  <div className="col m6">
<h5>Overseas: </h5>
  <ol>
    <li>Fouzia Mendra : Europe and America<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 00447472880050</li>
    <li>
    Morsali Houri : Algeria and the Maghreb  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i>
      00213798481647
    </li>
    <li>Munther Al-Kabeer : Malaysia  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 00601137011603</li>
  </ol>
  <h5>If you have any questions,Please email us at FICV2020@zu.edu.ly</h5>
</div>`,
};

export const D1 = {
  ar: `مواعيد مهمة`,
  en: `Important Dates`,
};
export const D2 = {
  ar: ` <li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  الإعلان عن المؤتمر: 12-5-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  بداية قبول الملخصات: من 12-5-2020 الى  11-06-2020 
 <span class=" red-text"> (تم التعديل)</span>
  </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  الإعلان عن قبول الملخصات المقبولة: 12-6-2020
  <span class=" red-text"> (تم التعديل)</span>
  </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
   قبول الورقات كاملة: من 12-6-2020 الى 06-7-2020

   <span class=" red-text"> (تم التعديل)</span>   </li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  إعلان الورقات المقبولة: 10-7-2020
  <span class=" red-text"> (تم التعديل)</span> 
  </li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  موعد إنعقاد المؤتمر: 12-7-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  ستكون كل جلسات المناقشة إلكترونية(online)وسيتم تزويدكم بالتفاصيل عبر البريد الإلكتروني في حالة قبول مشاركتكم
</li>
`,

  en: ` <li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference announcement: 12-May-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Abstract Submission due: 12-May-2020 to 11-June-2020 
  <span class=" red-text"> (Edited)</span> 
  </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Accepted Abstracts Notification: 12-June-2020
  <span class=" red-text"> (Edited)</span> 

</li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Paper Submission due: 12-June-2020 to 06-July-2020
  <span class=" red-text"> (Edited)</span> 

</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Accepted Paper Notification: 10-July-2020
  <span class=" red-text"> (Edited)</span>
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference Date: 12-July-2020
</li>
`,
};

export const footer = {
  ar: ` الموقع الالكتروني - جامعة الزاوية`,
  en: `Website Department-Unversity of Zawia`,
};

export const Close = {
  ar: `اغلاق`,
  en: `Close`,
};
