import React, { Fragment, useEffect } from "react";
import "./homepage.style.css";
import Timer from "../../components/timer/timer";
import M from "materialize-css/dist/js/materialize.min.js";
import RegisterationModal from "../../components/models/RegisterationModal";
import { connect } from "react-redux";
import ContactFilter from "../../components/models/SearchMadol";
import Spinner from "../Spinner/Spinner";
import { getResearchs, getConferance } from "../../actions/research";
import {
  head1,
  head2,
  head3,
  head4,
  head41,
  head5,
  head6,
  about1,
  about2,
  about3,
  about4,
  about5,
  about6,
  Gools1,
  Gools2,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  m8,
  m9,
  m10,
  m11,
  m12,
  m13,
  s,
  comission,
  c1,
  c2,
  D1,
  D2,
  Node,
  TNode,
  footer,
  Close,
} from "./sections";

const HomePage = ({
  match,
  lang,
  researchs,
  research,
  getResearchs,
  loading,
  // ChangeLang,
  conferance,
  getConferance,
}) => {
  useEffect(() => {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, {});
    var modal = document.querySelectorAll(".modal");
    M.Modal.init(modal, {});

    getConferance(match.params.cid);
    var tooltips = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(tooltips, {});
    conferance && getResearchs(conferance._id);
    // eslint-disable-next-line
  }, [loading]);

  let accepted = researchs && researchs.filter((r) => r.status === "accepted");
  let rejected = researchs && researchs.filter((r) => r.status === "rejected");
  return !conferance ? (
    <Fragment>
      <Spinner />
    </Fragment>
  ) : (
    <Fragment>
      <section className="showcase center">
        <div className="video-container">
          <video src="/img/1.mp4" autoPlay muted loop />
        </div>
        <div className="content ">
          {/* <div>
            <a
              href="#!"
              className={
                lang === "en" ? "btn right white-text" : "btn left white-text"
              }
              onClick={() => ChangeLang("ar")}
            >
              عربي
            </a>
            <a
              href="#!"
              className={
                lang === "en" ? "btn right white-text" : "btn left white-text"
              }
              onClick={() => ChangeLang("en")}
            >
              English
            </a>
          </div> */}

          <span>
            <img
              src={
                conferance.Logo && conferance.Logo !== ""
                  ? `http://db.conference.zu.edu.ly${conferance.Logo}`
                  : "/img/logo.png"
              }
              alt="logo"
              width="150"
              style={{ opacity: "0.7" }}
            />
          </span>

          <h4>المؤتمر العلمي بعنوان</h4>
          <h3>{conferance.title}</h3>

          <h3>
            <h4>{head5[lang]} </h4>
            <Timer lang={lang} date={conferance.ConferenceDate} />
          </h3>

          <br />
          <h4>
            {" "}
            {head3[lang]}
            {"   "}
            <span> {researchs && researchs.length}</span>
            {"   "}
            {head4[lang]} {"   "} {accepted.length}
            {"   "}
            {head41[lang]} {"   "} {rejected.length}
          </h4>
          {/* ************ استقبال البحوث حسب تاريخ الاستقبال****** */}
          {Date.now() < new Date(conferance.deadlineForReceiving) ? (
            <Fragment>
              {/* Regestration Botton */}

              <div className="fixed-action-btn">
                <a
                  href="#register"
                  className="modal-trigger btn-floating btn-large  red darken-2 pulse tooltipped"
                  data-position="left"
                  data-tooltip="لتسجيل في المؤتمر اضغط هنا"
                >
                  <i className="material-icons">note_add</i>
                </a>
              </div>
              <a
                href="#register"
                className="modal-trigger  hide-on-small-only btn-large red darken-2 waves-effect waves-light btn "
              >
                <i class="material-icons left">border_color</i>
                {lang === "ar"
                  ? "التسجيل في المؤتمر"
                  : "Register for the conference"}
              </a>
              <a
                href="#video"
                className="modal-trigger  hide-on-small-only btn-large red darken-2 waves-effect waves-light btn "
              >
                <i class="material-icons left">play_circle_filled</i>
                {lang === "ar" ? "كيفية التسجيل" : "How to Register"}
              </a>
            </Fragment>
          ) : (
            <a
              href="#list"
              className="modal-trigger   btn-large red darken-2 waves-effect waves-light btn "
            >
              {" "}
              <i class="material-icons left">format_list_numbered</i>
              {lang === "ar"
                ? " قائمة الملخصات المقبولة"
                : " accepted Abstructs list "}
            </a>
          )}
        </div>
      </section>
      {/* ********** نموذج ارسال البحوث ****** */}
      <RegisterationModal
        lang={lang}
        theHubSource={conferance.theHub}
        id={conferance._id}
      />
      <section
        id="about"
        className="container "
        style={{ direction: lang === "en" ? "ltr" : "rtl" }}
      >
        {/* *******التمهيد ******** */}
        <h3 className=" teal-text darken-4" style={{ marginTop: "50px" }}>
          {head6[lang]}
        </h3>
        <p style={{ fontSize: "1.3rem", lineHeight: "2.4rem" }}>
          {conferance.about}
        </p>
        {/* *********  الشروط و القواعد ********* */}
        <h5 style={{ lineHeight: "3rem" }}>
          {about2[lang]}
          <a
            target="_blank"
            class="waves-effect  modal-trigger teal-text"
            href={`http://db.conference.zu.edu.ly${conferance.rulesAndConditions}`}
          >
            {about3[lang]}
          </a>
        </h5>

        <div id="modal1" className="modal" style={{ width: "80%" }}>
          <div class="modal-content">
            <h4>{about4[lang]}</h4>
            <ol dangerouslySetInnerHTML={{ __html: about5[lang] }}></ol>
            <p className="center-align">{about6[lang]}</p>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              {Close[lang]}
            </a>
          </div>
        </div>

        <br />
      </section>
      {/* ********* الاهداف و المحاور و المواعيد********* */}
      <div id="description" className="description teal lighten-5">
        <div dangerouslySetInnerHTML={{ __html: conferance.Description }}></div>
      </div>
      {/* <!-- Section: Search --> */}

      {Date.now() < new Date("2020-07-09") && (
        <section
          id="search"
          class="section section-search teal darken-1 white-text center scrollspy"
        >
          <div class="container">
            <ContactFilter lang={lang} />
          </div>
        </section>
      )}

      {/* Video Madol */}
      <div id="video" class="modal">
        <div class="modal-content">
          <h4>
            {lang === "ar"
              ? "فيديو توضيحي لعملية التسجيل في المؤتمر"
              : "Video How to Register"}
          </h4>
          <div className=" video-madol">
            <video src="/img/how.mp4" controls />
          </div>
        </div>
        <div class="modal-footer">
          <a href="#!" class="modal-close waves-effect waves-green btn-flat">
            {Close[lang]}
          </a>
        </div>
      </div>
      {/* List Madol */}
      <div id="list" class="modal">
        <div class="modal-content">
          <h4>
            {lang === "ar"
              ? "  قائمة الملخصات المقبولة"
              : "Statistics and accepted Abstructs list "}
          </h4>
          <div>
            <ol>
              {accepted.map((acc) => (
                <li>
                  {acc.researchName} - {acc.author} - {acc.Organization} -{" "}
                  {acc.country}
                </li>
              ))}
            </ol>
          </div>
        </div>
        <div class="modal-footer">
          <a href="#!" class="modal-close waves-effect waves-green btn-flat">
            {Close[lang]}
          </a>
        </div>
      </div>

      <footer class="section teal darken-2 white-text center">
        <p class="flow-text">
          &copy; {footer[lang]}
          {"  "}
          {new Date().getFullYear()}
        </p>
      </footer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  researchs: state.research.researchs,
  lang: state.research.lang,
  research: state.research.research,
  conferance: state.research.conferance,
  loading: state.research.loading,
});

export default connect(mapStateToProps, {
  getResearchs,
  // ChangeLang,
  getConferance,
})(HomePage);
